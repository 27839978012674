import * as React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Col, Row, Container, Button } from "react-bootstrap"
import FadeIn from "../components/fade-in"

const BlogIndex = ({ data }) => {
  const posts = data.allMarkdownRemark.nodes

  if (posts.length === 0) {
    return (
      <Layout>
        <Seo
          title="Blog - Signaclass"
          description="Read our blog for hints and tips, advice and news about how to run your activity business."
        />

        <Container>
          <Row>
            <Col>
              <Bio />
              <p>
                No blog posts found. Add markdown posts to "content/blog" (or
                the directory you specified for the "gatsby-source-filesystem"
                plugin in gatsby-config.js).
              </p>
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }

  return (
    <Layout>
      <Seo
        title="Blog - Signaclass"
        description="Read our blog for hints and tips, advice and news about how to run your activity business."
      />

      <FadeIn delay={200} duration={1500}>
        <div className="shaded pt-5 pb-5" id="get-started-header" />
        <Container>
          <Row>
            <Col>
              <div className="shadow p-4 white text-center">
                <h1 className="text-center mt-3 mb-4">
                  <strong>Signaclass Blog</strong>
                </h1>
                <div>
                  {posts.map(post => {
                    const title = post.frontmatter.title || post.fields.slug

                    return (
                      <div key={post.fields.slug} className="pt-3 pb-3 mb-3">
                        <article
                          className="post-list-item"
                          itemScope
                          itemType="http://schema.org/Article"
                        >
                          <header>
                            <h2>
                              <Link to={post.fields.slug} itemProp="url">
                                <span itemProp="headline">{title}</span>
                              </Link>
                            </h2>
                            <small>{post.frontmatter.date}</small>
                          </header>
                          <section>
                            <p
                              dangerouslySetInnerHTML={{
                                __html:
                                  post.frontmatter.description || post.excerpt,
                              }}
                              itemProp="description"
                            />
                          </section>
                          <Button as={Link} to={post.fields.slug}>
                            Read
                          </Button>
                        </article>
                      </div>
                    )
                  })}
                </div>
                <Bio />
              </div>
            </Col>
          </Row>
        </Container>
      </FadeIn>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
